import React from "react";
import { useTranslation } from 'react-i18next';
import envConfig from '../../../../envConfig';
import ecommerce from '../../../../assets/images/ecommerce.png';
import { AnnotationIcon, ChartBarIcon, GlobeAltIcon, LightningBoltIcon, ScaleIcon } from "@heroicons/react/outline";

function CTA() {
    const { t } = useTranslation();
    const features = [
        {
          description: t('cta_desc_1'),
          icon: GlobeAltIcon,
        },
        {
          description: t('cta_desc_2'),
          icon: ScaleIcon,
        },
        {
          description: t('cta_desc_3'),
          icon: LightningBoltIcon,
        },
        {
          description: t('cta_desc_4'),
          icon: AnnotationIcon,
        },
        {
          description: t('cta_desc_5'),
          icon: ChartBarIcon,
        },
      ]
  return (
    <div className="pt-16 lg:pt-24">
      <div className="relative">
        <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
          <div className="relative shadow-xl sm:rounded-2xl sm:overflow-hidden">
            <div className="absolute inset-0">
              <img
                className="h-full w-full object-cover"
                src={ecommerce}
                alt="People working on laptops"
              />
              <div className="absolute inset-0 bg-gradient-to-r from-lightBlue-900 to-indigo-700 mix-blend-multiply" />
            </div>
            <div className="relative px-4 sm:px-6 sm:py-24 lg:px-8">
              <h1 className="text-center text-4xl font-extrabold tracking-tight sm:text-5xl lg:text-6xl">
                <span className="block text-white">{t('franchise_experts')}</span>
              </h1>
              <p className="mt-6 max-w-lg mx-auto text-center text-xl text-indigo-200 sm:max-w-3xl">
                {t('franchise_experts_desc')}
              </p>
              <div className="mt-10 max-w-sm mx-auto sm:max-w-none sm:flex sm:justify-center">
                <div className="space-y-4 sm:space-y-0 sm:mx-auto sm:inline-grid sm:grid-cols-2 sm:gap-5">
                  <a
                    href="/contact"
                    className="flex items-center justify-center px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-indigo-700 bg-white hover:bg-indigo-50 sm:px-8"
                  >
                    {t('contact_us')}
                  </a>
                  <a
                    href={envConfig.LiveDemoSite}
                    className="flex items-center justify-center px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-indigo-700 bg-white hover:bg-indigo-50 sm:px-8"
                  >
                    {t('live_demo')}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <div className="py-12 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="lg:text-center">
            <h2 className="text-lg text-gopanzaBlue font-bold tracking-wide uppercase">{t('easy_to_implement')} ​</h2>
          </div>
          <div className="mt-10">
            <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
              {features.map((feature) => (
                <div key={feature.name} className="relative">
                  <dt>
                    <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-gopanzaBlue text-white">
                      <feature.icon className="h-6 w-6" aria-hidden="true" />
                    </div>
                    <p className="ml-16 text-lg leading-6 font-medium text-gray-900">{feature.name}</p>
                  </dt>
                  <dd className="mt-2 ml-16 text-base text-gray-500">{feature.description}</dd>
                </div>
              ))}
            </dl>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CTA;
