import { atom } from 'recoil';

export const notificationsAtom = atom({
  key: 'notifications',
  default: [],
});

export const scrollToAtom = atom({
  key: 'scrollTo',
  default: null,
});

export const customersRefAtom = atom({
  key: 'customersRef',
  default: null,
});

export const teamRefAtom = atom({
  key: 'teamRef',
  default: null,
});

export const updatedAtom = atom({
  key: 'updated',
  default: new Date("2022-08-31 00:04:01"),
});