import React from 'react';
import { ChevronRightIcon } from "@heroicons/react/outline";
import { useTranslation } from 'react-i18next';
import { AnimatePresence, motion } from 'framer-motion';
import moment from 'moment';
import { updatedAtom } from '../../../atoms/Atoms';
import { useRecoilValue } from 'recoil';


export default function Terms() {
  const { t } = useTranslation();
  var date = useRecoilValue(updatedAtom)

  const data = [
    { description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris ac tempor nisl, at convallis neque.' },
    { description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris ac tempor nisl, at convallis neque.' },
    { description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris ac tempor nisl, at convallis neque.' },
    { description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris ac tempor nisl, at convallis neque.' },
    { description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris ac tempor nisl, at convallis neque.' },
  ];
  return (
    <AnimatePresence>
      <div className="bg-white">
        <div className="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
          <div className="text-center">
              {/* Header */}
              <p className="mt-1 text-xl tracking-tight font-bold text-gray-900 sm:text-2xl lg:text-4xl">
                {t('terms')}
              </p>
               {/* Subheader */}
               <p className="mt-1 text-md tracking-tight font-semibold text-gray-600 sm:text-xl lg:text-2xl">
                How we work and support you
              </p>
              {/* Last Update */}
              <p className="mt-1 text-md tracking-tight font-semibold text-gray-600 ">
                {`${t('updated')}: ${moment(date).format('MMMM Do YYYY')}`}
              </p>
              {/* Intro */}
              <p className="mt-8 text-md tracking-tight font-normal text-gray-600 px-0 text-justify">
              Welcome to Gopanza! By signing up for a Gopanza Account or by using any Gopanza Services (as defined below), you are agreeing to be bound by the following terms and conditions (the “Terms of Service”).
              </p>
              {/* Body */}
              <p className="mt-8 text-md tracking-tight font-semibold text-gray-600 flex flex-start">
              <ol>
                <h1 className="text-2xl text-left mb-2">Who we are</h1>
                <li className="text-md text-left font-normal ml-0">Gopanza is in the business of, among other things, developing and operating a complete commerce platform that enables its customers to unify their commerce activities through online or physical tools. All of this is offered by means of Gopanza’s proprietary technology and software.</li>
                <li className="text-md text-left font-semibold ml-4 mt-4">Our Services</li>
                <li className="text-md text-left font-normal ml-4">The Services include, but are not limited to, a range of tools for customers to build, customize and personalize online stores or sell in multiple places (including web, mobile, social media, online marketplaces and other online locations (“Online Services”) and in person (“POS Services”)) to manage products, inventory, payments, fulfillment, shipping, deliveries, business operations, marketing and advertising, and engage with existing and potential clients. Any such service or services offered by Gopanza are referred to as the “Service(s)”. Gopanza will make available the Services, a variety of Service packages, optional add-on Services that you may or not use.</li>
                <li className="text-md text-left font-semibold ml-4 mt-4">Your Rights</li>
                <li className="text-md text-left font-normal ml-4">During the term of your Agreement, you will have a limited, revocable, non-transferable and non-exclusive license to use the Services, including but not limited to any software, and related documentation, solely for your use to provide business related services that are consistent with the terms and conditions shared here.</li>
                <li className="text-md text-left font-semibold ml-4 mt-4">Your Obligations</li>
                <li className="text-md text-left font-normal ml-4">By using our services you will abide by the term set herein.</li>
              </ol>
              </p>             
              <p className="mt-12 text-md tracking-tight font-semibold text-gray-600 flex flex-start">
              <ol>
                <h1 className="text-2xl text-left mb-2">Technical Support</h1>     
                <li className="text-md text-left font-semibold ml-4 mt-4">How we support you.</li>          
                <li className="text-md text-left font-normal ml-4">Gopanza will work with you to ensure the successful installation and configuration of the Services, as well as provide support and assistance in such initial  installations. However, certain technical support shall be offered by Gopanza for the Services, which may include live chat with a technical support agent and/or assistance from a technical support agent via remote computer access, visits your physical locations for technical support or training, integration of the Gopanza platform for an additional cost.</li>
              </ol>
              </p>
              <p className="mt-12 text-md tracking-tight font-semibold text-gray-600 flex flex-start">
              <ol>
                <h1 className="text-2xl text-left mb-2">Availability, Downtime and General Services</h1>               
                <li className="text-md text-left font-normal ml-4">Gopanza provides all Services and any software related to those services to you on an "as is" basis and does not guarantee that the Services or any related software has no errors, defects or bugs or will function properly. The Parties acknowledge that since the Internet is neither owned nor controlled by any one entity, Gopanza makes no guarantees that any given user will be able to access the Services at any given time. Gopanza shall not be liable to you for failure of accessibility to the Services or any potential or actual losses that you may suffer from your inability to access or use the Services or your customer's inability to access any websites that may be supported or hosted by Services.</li>
              </ol>
              </p>
              <p className="mt-12 text-md tracking-tight font-semibold text-gray-600 flex flex-start">
              <ol>
                <h1 className="text-2xl text-left mb-2">Confidential Information</h1>     
                <li className="text-md text-left font-semibold ml-4 mt-4">Protection of Information and Non-Disclosure.</li>          
                <li className="text-md text-left font-normal ml-4">Gopanza provides all Services and any software related to those services to you on an "as is" basis and does not guarantee that the Services or any related software has no errors, defects or bugs or will function properly. You acknowledge that since the Internet is neither owned nor controlled by any one entity, Gopanza makes no guarantees that any given user will be able to access the Services at any given time. Gopanza shall not be liable for failure of accessibility to the Services or any potential or actual losses that you may suffer from your inability to access or use the Services or your customer's inability to access any websites that may be supported or hosted by Services.</li>
                <li className="text-md text-left font-semibold ml-4 mt-4">Confidential Information.</li>          
                <li className="text-md text-left font-normal ml-4">You understand that “Confidential Information” means any and all information and physical manifestations thereof not generally known or available outside Gopanza and information and physical manifestations thereof entrusted to Gopanza in confidence by third parties, whether or not such information is patentable, copyrightable or otherwise legally protectable. Confidential Information includes, without limitation: (i) Company Inventions (as defined below); and (ii) technical data, trade secrets, know-how, research, product or service ideas or plans, software codes and designs, algorithms, developments, inventions, patent applications, work product notebooks, processes, formulas, techniques, materials, mask works, engineering designs and drawings, hardware or software configuration information, agreements with third parties, lists of, or information relating to, contractors and consultants of Gopanza (including, but not limited to, the names, contact information, jobs, compensation, and expertise of such contractors and consultants), lists of, or information relating to, suppliers and customers (including, but not limited to, customers of Gopanza), price lists, pricing methodologies, cost data, market share data, marketing plans, licenses, contract information, business plans, financial forecasts, historical financial data, budgets or other business information disclosed to you by Gopanza either directly or indirectly, whether in writing, electronically, orally, or by observation.</li>
              </ol>
              </p>
              <p className="mt-12 text-md tracking-tight font-semibold text-gray-600 flex flex-start">
              <ol>
                <h1 className="text-2xl text-left mb-2">How you can reach us</h1>
                <li className="text-md text-left font-normal ml-0">If you would like to ask about, make a request relating to, or complain about how we process your personal information, please sedn un an email to <a className="text-blue-800 font-semibold" href="mailto:privacy@gopanza.com">Gopanza Support</a>.</li>
              </ol>
              </p>
          </div>
          <br />
          <br />
          <div className="prose max-w-none hidden">
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Expedita, hic? Commodi cumque
              similique id tempora molestiae deserunt at suscipit, dolor voluptatem, numquam, harum
              consequatur laboriosam voluptas tempore aut voluptatum alias?
            </p>
            <br />
            <dl>
              {data.map((data) => (
                <div key={data.name} className="relative">
                  <dt>
                    <ChevronRightIcon className="absolute h-6 w-6 text-green-500" aria-hidden="true" />
                      <p className="ml-9 text-sm leading-6 font-small text-gray-900">{data.description}</p>
                      <br />
                  </dt>
                </div>
                ))}
            </dl>
            <p>
              Pellentesque metus lorem, maximus et metus et, ullamcorper dignissim lorem.
              Ut erat ipsum, fermentum in ante eu, scelerisque volutpat enim. Donec in ex auctor, lobortis lorem in,
              tincidunt dolor. Pellentesque nec tempor orci. Ut bibendum mi sed est bibendum, id maximus risus placerat.
            </p>
          </div>
        </div>
      </div>
    </AnimatePresence>
  )
}
