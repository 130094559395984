import React from 'react';
import { useTranslation } from 'react-i18next';
import { AnimatePresence, motion } from 'framer-motion';
import moment from 'moment';
import { updatedAtom } from '../../../atoms/Atoms';
import { useRecoilValue } from 'recoil';

export default function Privacy() {
  const { t } = useTranslation();
  var date = useRecoilValue(updatedAtom)
  return (
    <AnimatePresence>
      <div className="bg-white">
        <div className="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
          <div className="text-center">
              {/* Header */}
              <p className="mt-1 text-xl tracking-tight font-bold text-gray-900 sm:text-2xl lg:text-4xl">
                {t('privacy_policy')}
              </p>
               {/* Subheader */}
               <p className="mt-1 text-md tracking-tight font-semibold text-gray-600 sm:text-xl lg:text-2xl">
                {t('how_we_deal_with_data')}
              </p>
              {/* Last Update */}
              <p className="mt-1 text-md tracking-tight font-semibold text-gray-600 ">
                {`${t('updated')}: ${moment(date).format('MMMM Do YYYY')}`}

              </p>
              {/* Intro */}
              <p className="mt-8 text-md tracking-tight font-normal text-gray-600 px-0 text-justify">
              This Privacy Policy will help you better understand how we collect, use, and share your personal information. If we change our privacy practices, we may update this privacy policy. If any changes are significant, we will let you know (for example, through the Gopanza admin or by email).
              </p>
              {/* Body */}
              <p className="mt-12 text-md tracking-tight font-semibold text-gray-600 flex flex-start">
              <ol>
                <h1 className="text-2xl text-left mb-2">Our Values</h1>
                <li className="text-md text-left font-normal ml-0">Trust is the foundation of the Gopanza platform and includes trusting us to do the right thing with your information. Three main values guide us as we develop our products and services. These values should help you better understand how we think about your information and privacy.</li>
                <li className="text-md text-left font-semibold ml-4 mt-4">Your information belongs to you</li>
                <li className="text-md text-left font-normal ml-4">We analyze what types of information we need to provide our services, and we try to limit the information we collect to only what we really need. Where possible, we delete or anonymize this information when we no longer need it. When building and improving our products, our engineers work closely with our privacy and security teams to build with privacy in mind. In all of this work our guiding principle is that your information belongs to you, and we aim to only use your information to your benefit.</li>
                <li className="text-md text-left font-semibold ml-4 mt-4">We protect your information from others</li>
                <li className="text-md text-left font-normal ml-4">If a third party requests your personal information, we will refuse to share it unless you give us permission or we are legally required. When we are legally required to share your personal information, we will tell you in advance, unless we are legally forbidden.</li>
                <li className="text-md text-left font-semibold ml-4 mt-4">We help merchants and partners meet their privacy obligations</li>
                <li className="text-md text-left font-normal ml-4">Many of the merchants and partners using Gopanza do not have the benefit of a dedicated privacy team, and it is important to us to help them meet their privacy obligations. To do this, we try to build our products and services so they can easily be used in a privacy-friendly way. We also provide documentation covering the most important privacy topics, and respond to privacy-related questions we receive.</li>
              </ol>
              </p>
              <p className="mt-12 text-md tracking-tight font-semibold text-gray-600 flex flex-start">
              <ol>
                <h1 className="text-2xl text-left mb-2">Your rights over your information</h1>               
                <li className="text-md text-left font-normal ml-4">We believe you should be able to access and control your personal information no matter where you live. Depending on how you use Gopanza, you may have the right to request access to, correct, amend, delete, port to another service provider, restrict, or object to certain uses of your personal information (for example, direct marketing). We will not charge you more or provide you with a different level of service if you exercise any of these rights.</li>
                <li className="text-md text-left font-normal ml-4 mt-4">If you buy something from a Gopanza-powered store and wish to exercise these rights over information about your purchase, you need to directly contact the merchant you interacted with. We are only a processor on their behalf, and cannot decide how to process their information. We will of course help our merchants to fulfill these requests by giving them the tools to do so and by answering their questions.</li>
                <li className="text-md text-left font-normal ml-4 mt-4">If you are a merchant, partner, consumer, Gopanza employee, or other individual that Gopanza has a direct relationship with, please submit your data subject request through our online portal. Please note that if you send us a request relating to your personal information, we have to make sure that it is you before we can respond. In order to do so, we may use a third party to collect and verify identification documents.</li>
                <li className="text-md text-left font-normal ml-4 mt-4">If you are not happy with our response to a request, you can contact us to resolve the issue. You also have the right to contact your local data protection or privacy authority at any time.</li>
                <li className="text-md text-left font-normal ml-4 mt-4">Many of the merchants and partners using Gopanza do not have the benefit of a dedicated privacy team, and it is important to us to help them meet their privacy obligations. To do this, we try to build our products and services so they can easily be used in a privacy-friendly way. We also provide documentation covering the most important privacy topics, and respond to privacy-related questions we receive.</li>
                <li className="text-md text-left font-normal ml-4 mt-4">Finally, if you beleive that somebody using our platoform has abused your right to control your information please contact us directly so we can mediate the issue in search for a quick solution.</li>
              </ol>
              </p>
              <p className="mt-12 text-md tracking-tight font-semibold text-gray-600 flex flex-start">
              <ol>
                <h1 className="text-2xl text-left mb-2">How you can reach us</h1>
                <li className="text-md text-left font-normal ml-0">If you would like to ask about, make a request relating to, or complain about how we process your personal information, please sedn un an email to <a className="text-blue-800 font-semibold" href="mailto:privacy@gopanza.com">Gopanza Support</a>.</li>
                {/* <li className="text-md text-left font-semibold ml-4 mt-4">Gopanza LLC.</li>
                <li className="text-md text-left font-normal ml-4">ATTN. Privacy Team</li>
                <li className="text-md text-left font-normal ml-4">Apt 3307 Portal de Sofía</li>
                <li className="text-md text-left font-normal ml-4">Guaynabo, PR 00969</li> */}
              </ol>
              </p>
          </div>
        </div>
      </div>
    </AnimatePresence>
  )
}
