import { CheckIcon } from "@heroicons/react/outline";
import React from "react";
import { useTranslation } from "react-i18next";

function HowItWorks() {
  const { t } = useTranslation();

    const features = [
        { description: t('how_desc_1') },
        { description: t('how_desc_2') },
        { description: t('how_desc_3') },
        { description: t('how_desc_4') },
        { description: t('how_desc_5') },
        { description: t('how_desc_6') },
        { description: t('how_desc_7') },
        { description: t('how_desc_8') },
      ];

  return (
    <div className="bg-white">
      <div className="max-w-7xl mx-auto pt-16 lg:pt-24 px-4 sm:px-6 lg:px-8 lg:grid lg:grid-cols-3 lg:gap-x-8">
        <div>
          <h2 className="text-base font-semibold text-indigo-600 uppercase tracking-wide">{t('how_it_work')}</h2>
          <p className="mt-2 text-3xl font-extrabold text-gray-900">{t('how_description')}​​</p>
        </div>
        <div className="mt-12 lg:mt-0 lg:col-span-2">
          <dl className="space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:grid-rows-4 sm:grid-flow-col sm:gap-x-6 sm:gap-y-10 lg:gap-x-8">
            {features.map((feature) => (
              <div key={feature.name} className="relative">
              <dt>
                    <CheckIcon className="absolute h-6 w-6 text-green-500" aria-hidden="true" />
                      <p className="ml-9 text-lg leading-6 font-medium text-gray-900">{feature.description}</p>
                    </dt>
                  </div>
                ))}
              </dl>
            </div>
          </div>
        </div>
      )
}

export default HowItWorks;
