import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMount, useLocalStorage } from 'react-use';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import NotFound from './pages/404/404';
import Header from './shared-components/Header';
import Footer from './shared-components/Footer';
import Home from './pages/home/Home';
import AboutUs from './pages/about/AboutUs'
import Contact from './pages/contact/Contact';
import Pricing from './pages/pricing/Pricing';
import Privacy from './pages/legal/PrivacyPolicy';
import Terms from './pages/legal/Terms';
import Notifications from './shared-components/Notifications';
import { AnimatePresence } from 'framer-motion';
import Payments from './pages/legal/Payments';

function App() {
  const { i18n } = useTranslation();
  const [language] = useLocalStorage('gopanza-lang');

  useMount(() => {
    if (language) i18n.changeLanguage(language);
  });

  return (
    <Router>
      <Notifications />
      <div className="text-gray-800 font-sans antialiased">
        <Header />
        <AnimatePresence exitBeforeEnter initial={false}>
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route path="/about">
              <AboutUs />
            </Route>
            <Route path="/contact">
              <Contact />
            </Route>
            <Route path="/pricing">
              <Pricing />
            </Route>
            <Route path="/legal/privacy">
              <Privacy />
            </Route>
            <Route path="/legal/terms">
              <Terms />
            </Route>
            <Route path="/legal/payments">
              <Payments />
            </Route>
            <Route path="*" exact>
              <NotFound />
            </Route>
          </Switch>
        </AnimatePresence>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
