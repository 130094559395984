import React from 'react';
import { useTranslation } from 'react-i18next';
import { AnimatePresence, motion } from 'framer-motion';
import moment from 'moment';
import { updatedAtom } from '../../../atoms/Atoms';
import { useRecoilValue } from 'recoil';

export default function Payments() {
  const { t } = useTranslation();
  var date = useRecoilValue(updatedAtom)
  return (
    <AnimatePresence>
      <div className="bg-white">
        <div className="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
          <div className="text-center">
              {/* Header */}
              <p className="mt-1 text-xl tracking-tight font-bold text-gray-900 sm:text-2xl lg:text-4xl">
                {t('fees_and_payments')}
              </p>
               {/* Subheader */}
               <p className="mt-1 text-md tracking-tight font-semibold text-gray-600 sm:text-xl lg:text-2xl">
                {t('how_we_deal_with_payments')}
              </p>
              {/* Last Update */}
              <p className="mt-1 text-md tracking-tight font-semibold text-gray-600 ">
                {`${t('updated')}: ${moment(date).format('MMMM Do YYYY')}`}

              </p>
              {/* Intro */}
              <p className="mt-8 text-md tracking-tight font-normal text-gray-600 px-0 text-justify">
              Gopanza receives payments on your behave thorugh our platform in many forms. Gopanza also incurrs in expenses to maintain your online staore avilable to your customers. Here in we share details for how both, fees and payments, are managed.
              </p>
              {/* Body */}
              <p className="mt-8 text-md tracking-tight font-semibold text-gray-600 flex flex-start">
              <ol>
                <h1 className="text-2xl text-left mb-2">Fees</h1>
                <li className="text-md text-left font-normal ml-0">In exchange for providing the Services Gopanza will receive the following payment in form of fees.</li>
                <li className="text-md text-left font-semibold ml-4 mt-4">Service Fees</li>
                <li className="text-md text-left font-normal ml-4">By using our services you agrees to pay a fixed monthly fee for each store using the Services.</li>
                <li className="text-md text-left font-semibold ml-4 mt-4">Transaction Fees</li>
                <li className="text-md text-left font-normal ml-4">You agree to pay a fee equal to a percentage of the sale for the transactions of the stores using the Services.</li>
                <li className="text-md text-left font-semibold ml-4 mt-4">Subscription Fees</li>
                <li className="text-md text-left font-normal ml-4">You agree to reimburse the totality of the monthly amount paid by Gopanza for Cloud Fees. Cloud Fees include costs for cloud services, hosting platform, messaging (SMS, email and any other) incurred during the regular month of usage of Services.</li>
              </ol>
              </p>
              <p className="mt-12 text-md tracking-tight font-semibold text-gray-600 flex flex-start">
              <ol>
                <h1 className="text-2xl text-left mb-2">Payments</h1>
                <li className="text-md text-left font-normal ml-0">Gopanza will transfer the corresponding revenue to the store for purchases made in the platform.</li>
                <li className="text-md text-left font-semibold ml-4 mt-4">Online Payments</li>
                <li className="text-md text-left font-normal ml-4">Gopanza will create certain accounts for the AGRANEL store to accept electronic payments of purchases made in the store. For payments that are paid for online, credit cards, peer to peer and other Gopanza will make daily deposits to the store's merchant account. Payment providers, such as PayPal charge a fee for each transaction paid by the customer online. This payment processing may vary based on the provider. All payment processing fees will be charged to the store and deducted for the daily transfers to the stores merchant account.</li>
                <li className="text-md text-left font-semibold ml-4 mt-4">Offline Payments</li>
                <li className="text-md text-left font-normal ml-4">Although we highly recommend against it, Gopanza allows stores to receive offline payments in the point of exchange with the customer. Examples of these forms of payments are Cash, ATH and Checks which are received by store representative or employee on a point-of-sale terminal usually provided by its bank. In the case of Offline Payment Gopanza will send a detailed invoice of all Offline Payments transactions to you from the previous month, including the detail of said Offline Payments. The store will have forty-eight (48) hours to make any dispute after receiving the invoice from Gopanza. Once the aforementioned time frame passes, the store will issue the payment to Gopanza for the full undisputed amount invoiced on or before five (5) days of Gopanza presenting said invoice.</li>
              </ol>
              </p>
          </div>
        </div>
      </div>
    </AnimatePresence>
  )
}
