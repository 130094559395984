import { CheckIcon } from "@heroicons/react/outline";
import React from "react";
import { useTranslation } from "react-i18next";
import ecommerce from '../../../../assets/images/ecommerce-2.jpg';

function WhyUs() {
  const { t } = useTranslation();

    const features = [
        { description: t('why_desc_1') },
        { description: t('why_desc_2') },
        { description: t('why_desc_3') },
        { description: t('why_desc_4') },
        { description: t('why_desc_5') },
        { description: t('why_desc_6')},
        { description: t('why_desc_7') },
      ];

  return (
    <div className="bg-white">
      <div className="max-w-7xl mx-auto pt-16 lg:pt-24 px-4 sm:px-6 lg:px-8">
        <div className="max-w-3xl mx-auto text-center">
          <h2 className="text-3xl font-extrabold text-gray-900">{t('why_gp')}</h2>
        </div>
        <dl className="mt-12 space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 lg:grid-cols-4 lg:gap-x-8">
          {features.map((feature) => (
            <div key={feature.name} className="relative">
              <dt>
                <CheckIcon className="absolute h-6 w-6 text-green-500" aria-hidden="true" />
                <p className="ml-9 text-lg leading-6 font-medium text-gray-900">{feature.name}</p>
              </dt>
              <dd className="mt-2 ml-9 text-base text-gray-500">{feature.description}</dd>
            </div>
          ))}
        </dl>
        <br />
        <p className="mt-1 text-sm text-gray-500">
          {t('according_to_mida')}      
        </p>
      </div>
    </div>
  )
}

export default WhyUs;
