import { t } from 'i18next';
import React from 'react';
import { useTranslation } from 'react-i18next';
import logo from '../../assets/images/gopanza_logo_full.png';
import envConfig from '../../envConfig';



const footerNavigation = [
  {
    name: 'social',
    links: [
      // {
      //   name: 'Facebook',
      //   href: 'https://www.facebook.com/Gopanza',
      // },
      // {
      //   name: 'Instagram',
      //   href: 'https://instagram.com/gopanza',
      // },
      {
        name: 'LinkedIn',
        href: 'https://www.linkedin.com/company/gopanza',
      },
    ]
  },
  {
    name: 'solutions',
    links: [
      { name: 'Demo', href: envConfig.LiveDemoSite },
      // { name: 'Analytics', href: '#' },
      // { name: 'Commerce', href: '#' },
      // { name: 'Insights', href: '#' },
    ]
  }
]


function Footer() {
  const { t } = useTranslation();

  const footerNavigation2 = [
    {
      name: 'support',
      links: [
        { name: t('pricing'), href: '/pricing' },
        { name: t('contact'), href: '/contact' },
        // { name: 'About', href: '/about' },
      ]
    },
    {
      name: 'legal',
      links: [
        { name: t('privacy_policy'), href: '/legal/privacy' },
        { name: t('terms'), href: '/legal/terms' },
        { name: t('payments'), href: '/legal/payments' },
      ]
    }
  ]

  return (
      <footer aria-labelledby="footerHeading">
        <h2 id="footerHeading" className="sr-only">
          Footer
        </h2>
        <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
          <div className="xl:grid xl:grid-cols-3 xl:gap-8">
            <div className="space-y-8 xl:col-span-1">
              <a href="/">
                <span className="sr-only">Gopanza LLC</span>
                <div className="flex flex-row w-42">
                 <img
                  className="h-8"
                  src={logo}
                  alt="Gopanza LLC"
                /></div>
              </a>
              <p className="text-gray-500 text-base">
               {t('eccomerce_made_simple')}
              </p>
            </div>
            <div className="mt-12 grid grid-cols-2 gap-8 xl:mt-0 xl:col-span-2">
              <div className="md:grid md:grid-cols-2 md:gap-8">
                {footerNavigation.map((navigation, index) => (
                  <div className={index % 2 == 0 ? '' : 'mt-12 md:mt-0'}>
                    <h3 className="text-sm font-semibold tracking-wider uppercase text-gray-900">{navigation.name}</h3>
                    <ul className="mt-4 space-y-4">
                      {navigation.links.map((link) => (
                        <li key={link.name}>
                          <a href={link.href} target="_blank" className="text-base text-gray-500 hover:text-gray-900">
                            {link.name}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                ))}
              </div>
              <div className="md:grid md:grid-cols-2 md:gap-8">
                {footerNavigation2.map((navigation, index) => (
                  <div className={index % 2 == 0 ? '' : 'mt-12 md:mt-0'}>
                    <h3 className="text-sm font-semibold tracking-wider uppercase text-gray-900">{navigation.name}</h3>
                    <ul className="mt-4 space-y-4">
                      {navigation.links.map((link) => (
                        <li key={link.name}>
                          <a href={link.href} target="_blank" className="text-base text-gray-500 hover:text-gray-900">
                            {link.name}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="mt-12 border-t border-gray-200 pt-8">
            <p className="text-base text-gray-400 xl:text-center">&copy; 2021 Gopanza LLC., All rights reserved.</p>
          </div>
        </div>
      </footer>
    );
  }
  
export default Footer;
