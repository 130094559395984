import React, { useState } from 'react';
import { CheckIcon } from '@heroicons/react/solid'
import { AnimatePresence, motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';

function Pricing() {
  const yearlyDiscount = 0.10;
  const { t } = useTranslation();
  const tiers = [
      {
        name: 'Silver',
        priceMonthly: 149,
        priceYearly: 1649,//(149*12*(1-yearlyDiscount)).toFixed(2),
        description: t('silver_desc'),
        includedFeatures: [
          t('feature_1'),
          t('feature_2'),
          t('feature_3'),
          t('feature_4'),
          t('feature_5'),
        ],
      },
      {
        name: 'Gold',
        priceMonthly: 349,
        priceYearly: 3999,//(349*12*(1-yearlyDiscount)).toFixed(2),
        description: t('plat_gold_desc'),
        includedFeatures: [
          t('feature_1'),
          t('feature_2'),
          t('feature_3'),          
          t('feature_4'),
          t('feature_5'),
          t('feature_6'),
          t('feature_7'),
          t('feature_8'),
          t('feature_9')
        ],
      },
      {
        name: 'Platinum',
        priceMonthly: 499,
        priceYearly: 5900,//(449*12*(1-yearlyDiscount)).toFixed(2),
        description: t('plat_gold_desc'),
        includedFeatures: [
          t('feature_1'),
          t('feature_2'),
          t('feature_3'),
          t('feature_4'),
          t('feature_5'),
          t('feature_14'),        
          t('feature_7'),
          t('feature_8'),
          t('feature_9'),
          t('feature_10'),
          t('feature_11'),
          t('feature_12'),
          t('feature_13')
        ],
      },
  ];
  const selectorStyles = {
    selected: 'relative w-1/2 bg-white border-gray-200 rounded-md shadow-sm py-2 text-sm font-medium text-gray-900 whitespace-nowrap focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:z-10 sm:w-auto sm:px-8',
    unselected: 'ml-0.5 relative w-1/2 border border-transparent rounded-md py-2 text-sm font-medium text-gray-700 whitespace-nowrap focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:z-10 sm:w-auto sm:px-8'
  }
  const [showYearlyPrice, setShowYearlyPrice] = useState(false);
  return (
    <AnimatePresence>
      <div className="py-10">
        <div className="max-w-7xl mx-auto py-24 px-4 sm:px-6 lg:px-8">
          <motion.div
            className="sm:flex sm:flex-col sm:align-center"
            positionTransition
            initial={{ opacity: 0, y: -1000, }}
            animate={{
              opacity: 1,
              y: 0,
              transition: { type: 'tween', duration: 0.5 },
            }}
          >
            <h1 className="text-5xl font-extrabold text-gray-900 sm:text-center">{t('pricing_plans')}</h1>
            <p className="mt-5 text-xl text-gray-500 sm:text-center">
            {t('pricing_desc')}
            </p>
            <div className="relative self-center mt-6 bg-gray-100 rounded-lg p-0.5 flex sm:mt-8">
              <button
                type="button"
                className={showYearlyPrice ? selectorStyles.unselected : selectorStyles.selected}
                onClick={() => setShowYearlyPrice(false)}
              >
                {t('monthly_billing')}
              </button>
              <button
                type="button"
                className={showYearlyPrice ? selectorStyles.selected : selectorStyles.unselected}
                onClick={() => setShowYearlyPrice(true)}
              >
                {t('yearly_billing')}
              </button>
            </div>
          </motion.div>
          <motion.div
            className="self-center mt-12 space-y-4 sm:mt-16 sm:space-y-0 sm:grid sm:grid-cols-1 sm:gap-6 lg:max-w-4xl lg:mx-auto xl:max-w-none xl:mx-0 xl:grid-cols-3"
            positionTransition
            initial={{ opacity: 0, y: 1000, }}
            animate={{
              opacity: 1,
              y: 0,
              transition: { type: 'spring', duration: 0.5 },
            }}
          >
            {tiers.map((tier) => (
              <div
                key={tier.name}
                className="border border-gray-200 rounded-lg shadow-sm divide-y divide-gray-200"
              >
                <div className="p-6">
                  <h2 className="text-lg leading-6 font-medium text-gray-900">{tier.name}</h2>
                  <p className="mt-4 text-sm text-gray-500">{tier.description}</p>
                  <p className="mt-8">
                    <span className="text-4xl font-extrabold text-gray-900">${showYearlyPrice ? tier.priceYearly : tier.priceMonthly}</span>{' '}
                    <span className="text-base font-medium text-gray-500">/{showYearlyPrice ? 'yr' : 'mo'}</span>
                  </p>
                  {/* <a
                    href={tier.href}
                    className="mt-8 block w-full bg-gray-800 border border-gray-800 rounded-md py-2 text-sm font-semibold text-white text-center hover:bg-gray-900"
                  >
                    Buy {tier.name}
                  </a> */}
                </div>
                <div className="pt-6 pb-8 px-6">
                  <h3 className="text-xs font-medium text-gray-900 tracking-wide uppercase">{t('whats_included')}</h3>
                  <ul role="list" className="mt-6 space-y-4">
                    {tier.includedFeatures.map((feature) => (
                      <li key={feature} className="flex space-x-3">
                        <CheckIcon className="flex-shrink-0 h-5 w-5 text-green-500" aria-hidden="true" />
                        <span className="text-sm text-gray-500">{feature}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            ))}
          </motion.div>
          <div className="max-w-7xl mx-auto text-center pt-12 px-4 sm:px-6 lg:px-8">
            <h2 className="text-xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              <span className="block">{t('specialized_tier')}</span>
              <p>{t('specialized_tier_mess')}</p>
            </h2>
            <div className="mt-8 flex justify-center">
              <div className="inline-flex rounded-md shadow">
                <a
                  href="/contact"
                  className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 md:py-4 md:text-lg md:px-10"
                >
                  {t('contact_us')}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AnimatePresence>
  );
}

export default Pricing;
