import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
} from 'antd';

function LanguageSwitcher({ className, small }) {
  const { i18n } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);

  function changeLanguage(lang) {
    setSelectedLanguage(lang);
    i18n.changeLanguage(lang).then(() => {
      window.localStorage.setItem(
        'gopanza-lang',
        JSON.stringify(lang)
      );
    });
  }

  return (
    <span className="relative z-0 inline-flex shadow-sm rounded-md">
      <button
        type="button"
        onClick={() => changeLanguage('en')}
        className={selectedLanguage === 'en' ? " inline-flex items-center text-sm flex-grow-0 bg-indigo-100 space-x-2 flex items-center rounded px-3 py-2 font-medium tracking-tight border border-white border-opacity-20 hover:border-opacity-30 outline-none focus:outline-none transition duration-500 ease-in-out" : " inline-flex items-center text-sm flex-grow-0 space-x-2 flex items-center rounded px-3 py-2 font-medium tracking-tight border border-white border-opacity-20 hover:border-opacity-30 outline-none focus:outline-none transition duration-500 ease-in-out"}
      >
        English
      </button>
      <button
        type="button"
        onClick={() => changeLanguage('es')}
        className={selectedLanguage === 'es' ? " inline-flex items-center text-sm flex-grow-0 bg-indigo-100 space-x-2 flex items-center rounded px-3 py-2 font-medium tracking-tight border border-white border-opacity-20 hover:border-opacity-30 outline-none focus:outline-none transition duration-500 ease-in-out" : " inline-flex items-center text-sm flex-grow-0 space-x-2 flex items-center rounded px-3 py-2 font-medium tracking-tight border border-white border-opacity-20 hover:border-opacity-30 outline-none focus:outline-none transition duration-500 ease-in-out"}
      >
        Español
      </button>
  </span>
  );
}

export default LanguageSwitcher;
