import React, { useState } from 'react';
import { useRecoilState } from 'recoil';
import axios from 'axios';
import { notificationsAtom } from '../../../atoms/Atoms';
import { Spinner } from '../../shared-components/Spinner';
import office from '../../../assets/images/office.jfif';
import envConfig from '../../../envConfig';
import { AnimatePresence, motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';

function Contact() {
  const [notifications, setNotifications] = useRecoilState(notificationsAtom);

  const [loading, setLoading] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [company, setCompany] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');
  const [businessType, setBusinessType] = useState('');
  const [howDidyouHear, setHowDidyouHear] = useState('');
  const [businessTypeError, setBusinessTypeError] = useState('');
  const [comapnyError, setCompanyError] = useState('');
  const [firstNameError, setFirstNameError] = useState('');
  const [lastNameError, setLastNameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [messageError, setMessageError] = useState('');
  const { t } = useTranslation();

  const checkEmail = function (value) {
    var emailPattern = new RegExp(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    return emailPattern.test(value);
  };

  const checkPhone = function (value) {
    var phonePattern = new RegExp(/^[0-9\b]+$/);
    return phonePattern.test(phone);
  };

  const validate = function () {
    let hasError = false;
    if (firstName?.length === 0) {
      setFirstNameError('First Name is not valid');
      hasError = true;
    }
    if (lastName?.length === 0) {
      setLastNameError('Last Name is not valid');
      hasError = true;
    }
    if (company?.length === 0) {
      setCompanyError('Company is not valid');
      hasError = true;
    }
    if (email?.length === 0 || !checkEmail(email)) {
      setEmailError('Email is not valid');
      hasError = true;
    }
    if (phone?.length > 0 && !checkPhone(phone)) {
      setEmailError('Email is not valid');
      hasError = true;
    }
    if (message?.length === 0) {
      setMessageError('Message should not be empty');
      hasError = true;
    }
    if (businessType?.length === 0) {
      setBusinessTypeError('Business Type is not valid');
      hasError = true;
    }
    return hasError;
  };

  const submit = function () {
    if(!validate()) {
      setLoading(true);
      axios.post(`${envConfig.ApiUrl}/contact`, {
        FirstName: firstName,
        LastName: lastName,
        Email: email,
        Phone: phone,
        Companay: company,
        HowDidYouHear: howDidyouHear,
        TypeOfBusiness: businessType,
        Message: message
      }, {
        headers: {
          'Ocp-Apim-Subscription-Key': envConfig.ApiKey,
        }
      }).then((response) => {
        setNotifications([
          ...notifications,
          {
            title: 'Thank you!',
            description: 'We\'ll resposne as soon as possible.',
            error: false,
          },
        ]);
        setLoading(false);
        setFirstName('');
        setLastName('');
        setEmail('');
        setCompany('');
        setPhone('');
        setMessage('');
        setBusinessType('');
        setHowDidyouHear('');
      });
    }
  };

  return (
    <AnimatePresence>
      <motion.div
        positionTransition
        initial={{ opacity: 0, y: -150, }}
        animate={{
          opacity: 1,
          y: 0,
          transition: { type: 'spring' },
        }}
        className="bg-gray-200 py-16"
      >
        <div className="max-w-md mx-auto pl-4 pr-8 sm:max-w-lg sm:px-6 lg:max-w-7xl lg:px-8">
          <h1 className="text-4xl leading-10 font-extrabold tracking-tight text-gray-900 text-center sm:text-5xl sm:leading-none lg:text-6xl">
            {/* Contact us to get started */}
            {t('work_together')}
          </h1>
          <p className="mt-6 max-w-3xl mx-auto text-xl leading-normal text-gray-500 text-center">
            {/* Share basic information about your business to help us understand how to start. */}
            {t('work_together_description')} <br />{t('or')} <a className="text-indigo-500" href="mailto:hello@gopanza.com">{t('email_us')}</a>.
          </p>
        </div>
      </motion.div>
      <div className="relative bg-white">
        <motion.div
          positionTransition
          initial={{ opacity: 0, x: 1000, }}
          animate={{
            opacity: 1,
            x: 0,
            transition: { type: 'tween', duration: 0.5 },
          }}
          className="lg:absolute lg:inset-0"
        >
          <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
            <img
              className="h-56 w-full object-cover lg:absolute lg:h-full"
              src={office}
              alt="Work Office"
            />
          </div>
        </motion.div>
        <motion.div
          className="relative px-4 py-10 sm:px-6 lg:px-8 lg:max-w-7xl lg:mx-auto lg:grid lg:grid-cols-2"
          positionTransition
          initial={{ opacity: 0, x: -1000, }}
          animate={{
            opacity: 1,
            x: 0,
            transition: { type: 'tween', duration: 0.5 },
          }}
        >
          <div className="lg:pr-8">
            <div className="max-w-md mx-auto sm:max-w-lg lg:mx-0">
              <h2 className="hidden text-3xl font-extrabold tracking-tight sm:text-4xl">{t('work_together')}</h2>
              <p className="hidden mt-4 text-lg text-gray-500 sm:mt-3">
              {t('work_together_description')}, {t('or')} <a className="text-indigo-500" href="mailto:hello@gopanza.com">{t('email_us')}</a>.
              </p>
              <div className="grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8">
                <div>
                  <label htmlFor="first_name" className="block text-base font-medium text-gray-700">
                    {t('first_name')}
                    <small className="text-red-500 ml-2">{firstNameError}</small>
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      name="first_name"
                      disabled={loading}
                      value={firstName}
                      id="first_name"
                      autoComplete="given-name"
                      onChange={(obj) => {
                        setFirstNameError('');
                        setFirstName(obj.target.value);
                      }}
                      className="block w-full shadow-sm sm:text-base focus:ring-grape-500 focus:border-grape-500 border-gray-300 rounded-md"
                    />
                  </div>
                </div>
                <div>
                  <label htmlFor="last_name" className="block text-base font-medium text-gray-700">
                    {t('last_name')}
                    <small className="text-red-500 ml-2">{lastNameError}</small>
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      name="last_name"
                      disabled={loading}
                      value={lastName}
                      id="last_name"
                      autoComplete="family-name"
                      onChange={(obj) => {
                        setLastNameError('');
                        setLastName(obj.target.value);
                      }}
                      className="block w-full shadow-sm sm:text-base focus:ring-grape-500 focus:border-grape-500 border-gray-300 rounded-md"
                    />
                  </div>
                </div>
                <div className="sm:col-span-2">
                  <label htmlFor="email" className="block text-base font-medium text-gray-700">
                    {t('email')}
                    <small className="text-red-500 ml-2">{emailError}</small>
                  </label>
                  <div className="mt-1">
                    <input
                      id="email"
                      name="email"
                      disabled={loading}
                      type="email"
                      value={email}
                      autoComplete="email"
                      onChange={(obj) => {
                        setEmailError('');
                        if(!checkEmail(obj.target.value)) {
                          setEmailError('Email is not valid');
                        }
                        setEmail(obj.target.value);
                      }}
                      className="block w-full shadow-sm sm:text-base focus:ring-grape-500 focus:border-grape-500 border-gray-300 rounded-md"
                    />
                  </div>
                </div>
                <div className="sm:col-span-2">
                  <label htmlFor="company" className="block text-base font-medium text-gray-700">
                    {t('company')}
                    <small className="text-red-500 ml-2">{comapnyError}</small>
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      name="company"
                      disabled={loading}
                      id="company"
                      value={company}
                      autoComplete="organization"
                      onChange={(obj) => {
                        setCompanyError('');
                        setCompany(obj.target.value);
                      }}
                      className="block w-full shadow-sm sm:text-base focus:ring-grape-500 focus:border-grape-500 border-gray-300 rounded-md"
                    />
                  </div>
                </div>
                <div className="sm:col-span-2">
                  <div className="flex justify-between">
                    <label htmlFor="phone" className="block text-base font-medium text-gray-700">
                      {t('phone')}
                      <small className="text-red-500 ml-2">{phoneError}</small>
                    </label>
                    <span id="phone_description" className="text-base text-gray-500">
                      {t('optional')}
                    </span>
                  </div>
                  <div className="mt-1">
                    <input
                      type="text"
                      name="phone"
                      disabled={loading}
                      id="phone"
                      value={phone}
                      autoComplete="tel"
                      aria-describedby="phone_description"
                      onChange={(obj) => {
                        setPhoneError('');
                        if(obj.target.value.length > 0 && !checkPhone(obj.target.value)) {
                          setPhoneError('Phone is not valid');
                        }
                        setPhone(obj.target.value);
                      }}
                      className="block w-full shadow-sm sm:text-base focus:ring-grape-500 focus:border-grape-500 border-gray-300 rounded-md"
                    />
                  </div>
                </div>
                <div className="sm:col-span-2">
                  <div className="flex justify-between">
                    <label htmlFor="how_can_we_help" className="block text-base font-medium text-gray-700">
                      {t('how_can_we_help')}
                      <small className="text-red-500 ml-2">{messageError}</small>
                    </label>
                    <span id="how_can_we_help_description" className="text-base text-gray-500">
                      {t('max_characters')}
                    </span>
                  </div>
                  <div className="mt-1">
                    <textarea
                      id="how_can_we_help"
                      name="how_can_we_help"
                      disabled={loading}
                      aria-describedby="how_can_we_help_description"
                      rows={4}
                      value={message}
                      className="block w-full shadow-sm sm:text-base focus:ring-grape-500 focus:border-grape-500 border border-gray-300 rounded-md"
                      defaultValue={''}
                      onChange={(obj) => {
                        setMessageError('');
                        setMessage(obj.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="sm:col-span-2">
                  <label htmlFor="businessType" className="block text-base font-medium text-gray-700">
                    {t('type_of_business')} 
                    <small className="text-red-500 ml-2">{businessTypeError}</small>
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      name="businessType"
                      value={businessType}
                      disabled={loading}
                      id="businessType"
                      onChange={(obj) => {
                        setBusinessTypeError('');
                        setBusinessType(obj.target.value);
                      }}
                      className="block w-full shadow-sm sm:text-base focus:ring-grape-500 focus:border-grape-500 border-gray-300 rounded-md"
                    />
                  </div>
                </div>
                <div className="sm:col-span-2">
                  <div className="flex justify-between">
                    <label htmlFor="how_did_you_hear_about_us" className="block text-base font-medium text-gray-700">
                      {t('hear_about_us')}
                    </label>
                    <span id="how_did_you_hear_about_us_description" className="text-base text-gray-500">
                      {t('optional')}
                    </span>
                  </div>
                  <div className="mt-1">
                    <input
                      type="text"
                      name="how_did_you_hear_about_us"
                      disabled={loading}
                      value={howDidyouHear}
                      id="how_did_you_hear_about_us"
                      className="shadow-sm focus:ring-grape-500 focus:border-grape-500 block w-full sm:text-base border-gray-300 rounded-md"
                      onChange={(obj) => {
                        setHowDidyouHear(obj.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="text-right sm:col-span-2">
                  <button
                    type="primary"
                    onClick={() => submit()}
                    className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 md:py-4 md:text-lg md:px-10"
                  >
                    <Spinner spinning={loading} className="mr-2" />
                    {t('submit')}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </motion.div>
      </div>
    </AnimatePresence>
  );
}
  
export default Contact;
