import React, { Fragment } from 'react';
import { useSetRecoilState } from 'recoil';
import { useHistory } from 'react-router-dom';
import { scrollToAtom } from '../../atoms/Atoms';
import { Popover, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import logo from '../../assets/images/gopanza_logo_full.png';
import LanguageSwitcher from '../shared-components/LanguageSwitcher';
import { useTranslation } from 'react-i18next';

function Header() {
  const history = useHistory();
  const setScrollTo = useSetRecoilState(scrollToAtom);
  const { t } = useTranslation();

  const navigation = [
    { name: t('solution'), href: '/' },
    { name: t('pricing'), href: '/pricing' },
    // { name: 'About', href: '/about' },
    { name: t('contact'), href: '/contact' },
  ];

  return (
    <div className="sticky top-0 z-20">
      <div className="relative bg-white overflow-hidden">
        <div className="max-w-7xl mx-auto">
          <div className="relative z-10 bg-white pb-4 lg:max-w-2xl lg:w-full">
            <svg
              className="hidden lg:block absolute right-0 inset-y-0 h-full w-48 text-white transform translate-x-1/2"
              fill="currentColor"
              viewBox="0 0 100 100"
              preserveAspectRatio="none"
              aria-hidden="true"
            >
              <polygon points="50,0 100,0 50,100 0,100" />
            </svg>
  
            <Popover>
              {({ open }) => (
                <>
                  <div className="relative pt-6 px-4 sm:px-6 lg:px-8">
                    <nav
                      className="relative flex items-center justify-between sm:h-10 lg:justify-start"
                      aria-label="Global"
                    >
                      <div className="flex items-center flex-grow flex-shrink-0 lg:flex-grow-0">
                        <div className="flex flex-row items-center justify-between w-full md:w-auto">
                          <a href="/">
                            <span className="sr-only">Gopanza LLC</span>
                            <div className="flex flex-row">
                             <img
                              className="w-52"
                              src={logo}
                              alt="Gopanza LLC"
                            /></div>
                          </a>
                        </div>
                      </div>
                      <div className="hidden md:block md:ml-10 md:pr-4 md:space-x-8">
                        {navigation.map((item) => (
                          <>
                            {item.refId && (
                              <a
                                key={item.name}
                                className="font-medium text-gray-500 hover:text-gray-900"
                                onClick={() => {
                                  setScrollTo(item.refId);
                                  if (window.location.pathname !== '/') {
                                    history.push(`/`);
                                  }
                                }}
                              >
                                {item.name}
                              </a>
                            )}
                            {item.href && (
                              <a
                                key={item.name}
                                className="font-medium text-gray-500 hover:text-gray-900"
                                href={item.href}
                              >
                                {item.name}
                              </a>
                            )}
                          </>
                        ))}
                      </div>
                      <div className="mt-4 flex md:mt-0 md:ml-4">
                        <LanguageSwitcher style={{ aling: "right" }} className="mr-2" small />
                      </div>
                    </nav>
                  </div>
                  <Transition
                    show={open}
                    as={Fragment}
                    enter="duration-150 ease-out"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="duration-100 ease-in"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                  >
                    <Popover.Panel
                      focus
                      static
                      className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
                    >
                      <div className="rounded-lg shadow-md bg-white ring-1 ring-black ring-opacity-5 overflow-hidden">
                        <div className="px-5 pt-4 flex items-center justify-between">
                          <div>
                            <img
                              className="h-8 w-auto"
                              src="https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg"
                              alt=""
                            />
                          </div>
                          <div className="-mr-2">
                            <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                              <span className="sr-only">{t('close_main_menu')}</span>
                              <XIcon className="h-6 w-6" aria-hidden="true" />
                            </Popover.Button>
                          </div>
                        </div>
                        <div className="px-2 pt-2 pb-3 space-y-1">
                          {navigation.map((item) => (
                            <>
                              {item.refId && (
                                <a
                                  key={item.name}
                                  className="font-medium text-gray-500 hover:text-gray-900"
                                  onClick={() => {
                                    setScrollTo(item.refId);
                                    if (window.location.pathname !== '/') {
                                      history.push(`/`);
                                    }
                                  }}
                                >
                                  {item.name}
                                </a>
                              )}
                              {item.href && (
                                <a
                                  key={item.name}
                                  className="font-medium text-gray-500 hover:text-gray-900"
                                  href={`/${item.href}`}
                                >
                                  {item.name}
                                </a>
                              )}
                            </>
                          ))}
                        </div>
                      </div>
                    </Popover.Panel>
                  </Transition>
                </>
              )}
            </Popover>
          </div>
        </div>
      </div>
    </div>
  );
}
  
export default Header;
