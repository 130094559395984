import React from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import lahacienda from '../../../../assets/images/lahacienda.png';
import antoninos from '../../../../assets/images/antoninos.png';
import plazaloiaza from '../../../../assets/images/plz.png';
import readykart from '../../../../assets/images/Logo-Ready-Kart_2022.png';

const customers = [
  {
    name: 'La Hacienda Foods',
    image: lahacienda,
    url: 'https://lahaciendafoods.shop',
    locations: 4
  },
  {
    name: 'Hatillo Kash n Karry',
    image: readykart,
    url: 'https://www.readykart.com/',
    locations: 4
  },
  // {
  //   name: 'Plaza Loiza',
  //   image: plazaloiaza,
  //   url: 'https://shop.plazaloiza.com',
  //   locations: 4
  // },
  {
    name: 'Antonino\'s',
    image: antoninos,
    url: 'https://shop.antoninosusa.com',
    locations: 30
  },
]


function CustomerReferences() {
  const { t } = useTranslation();
return (
      <div className="bg-gopanzaBlue">
        <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8 text-white">
          <h2 className="text-3xl font-bold">{t('customers_desc')}​</h2>
          <div className="flow-root mt-8 lg:mt-10">
            <div className="-mt-4 -ml-8 flex flex-wrap justify-between lg:-ml-4">
              {_.map(customers, (customer) => (
                <div className="mt-4 ml-8 flex flex-grow flex-shrink-0 lg:flex-grow-0 lg:ml-4">
                    <div>
                      <a href={customer.url} target="_blank" className="text-gray-400 hover:text-gray-500">
                        <img className="h-12 mx-auto" src={customer.image} alt={customer.name} />  
                      </a>
                      <br />
                      <p className="inline-flex justify-center w-full text-base font-medium">{' '} {customer.locations} {t('locations')} {' '}</p>
                    </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
  
export default CustomerReferences;
